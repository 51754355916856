import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Img from "gatsby-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { PageTitle } from "../components/pageTitle";
import Hero from "../components/hero";
import RichText from "../components/richText";
import ContactForm from "../components/contactForm";

function StandardPageTemplate({ data }) {
  const { title, body, image } = data.contentfulPage;
  const { json } = body;
  const breadcrumbs = [{ name: "Home", href: "/" }, { name: title }];
  const { contactPhoneNumber } = data.site.siteMetadata;
  return (
    <Layout
      hero={
        <Hero breadcrumbs={breadcrumbs}>
          <PageTitle id="mainContent">{title}</PageTitle>
        </Hero>
      }
    >
      <SEO title={title} />
      <FlexContainer>
        <MaxWidthBox>
          <MapImg fluid={image.fluid} />
          <CustomRichText>{documentToReactComponents(json)}</CustomRichText>
        </MaxWidthBox>
        <ContactDetails>
          <p>
            Call us on:{" "}
            <PhoneNumber href={`tel:${contactPhoneNumber}`}>
              {contactPhoneNumber}
            </PhoneNumber>
          </p>
          <ContactForm css="margin-top: 1rem;" />
        </ContactDetails>
      </FlexContainer>
    </Layout>
  );
}

export default StandardPageTemplate;

export const pageQuery = graphql`
  query ContactPageBySlug($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      slug
      body {
        json
      }
      image {
        fluid(maxWidth: 800) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        contactPhoneNumber
      }
    }
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -1rem;
  margin-right: -1rem;
  margin-left: -1rem;

  @media (min-width: 50em) {
    margin-top: -2rem;
    margin-right: -2rem;
    margin-left: -2rem;
  }
`;

const MaxWidthBox = styled.div`
  flex-grow: 1;
  flex-basis: 25rem;
  max-width: 60ch;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;

  @media (min-width: 50em) {
    padding-top: 2rem;
    padding-right: 2rem;
    padding-left: 2rem;
  }
`;

const MapImg = styled(Img)`
  margin-top: 1em;
`;

const CustomRichText = styled(RichText)`
  > ul {
    > li {
      > p {
        margin-top: 0;
      }
    }
  }
`;

const ContactDetails = styled.div`
  min-width: 45%;
  flex-basis: 0;
  flex-grow: 666;
  flex-shrink: 0;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;

  @media (min-width: 50em) {
    padding-top: 2rem;
    padding-right: 2rem;
    padding-left: 2rem;
  }
`;

const PhoneNumber = styled.a`
  display: block;
  margin-top: 0.5rem;
  font-size: 2.5em;
  font-weight: bold;
  line-height: 1;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.darkBlue};
`;
