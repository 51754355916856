import styled from "styled-components";
import { transparentize } from "polished";

export const Button = styled.button`
  margin: initial;
  padding: initial;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  text-align: inherit;
  text-transform: inherit;
  background-color: transparent;
  color: inherit;

  display: inline-block;
  padding: 0.4em 0.8em;
  border: 1px solid ${({ theme }) => theme.palette.darkerLightBlue};
  border-radius: 0.5rem;
  text-align: center;
  transition: background-color 0.35s, color 0.35s;
  background-color: ${({ theme, isSecondary }) =>
    isSecondary
      ? transparentize(0.5, theme.palette.white)
      : theme.palette.darkerLightBlue};
  color: ${({ theme, isSecondary }) =>
    isSecondary ? theme.palette.darkerLightBlue : theme.palette.white};
  overflow: hidden;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.palette.darkBlue};
    color: ${({ theme }) => theme.palette.white};
  }

  &:focus {
    box-shadow: 0 0 0.125rem 0.125rem
      ${({ theme }) => transparentize(0.25, theme.palette.lightBlue)};
    outline: none;
  }
`;
