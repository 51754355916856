import React, { Component } from "react";
import styled, { css, keyframes } from "styled-components";
import { Button } from "./button";
import { transparentize } from "polished";

const isLoading = ({ isLoading }) =>
  isLoading &&
  css`
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
  `;

const StyledContactForm = styled.form`
  ${isLoading};

  display: grid;
  grid-gap: 1.5rem;
  position: relative;
  max-width: 60ch;
`;

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const buttonIsLoading = ({ isLoading }) =>
  isLoading &&
  css`
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: ${({ theme }) => theme.palette.darkBlue};
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1.5em;
      height: 1.5em;
      margin-top: -0.75em;
      margin-left: -0.75em;
      border-width: 4px;
      border-style: solid;
      border-color: ${({ theme }) => theme.palette.darkerLightBlue};
      border-left-color: ${({ theme }) => theme.palette.white};
      border-radius: 50%;
      animation: ${spin} 0.35s linear infinite;
      z-index: 2;
    }
  `;

const Success = styled.div`
  margin-top: 1em;
  padding: 1rem;
  text-align: center;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.palette.green};
  color: ${({ theme }) => theme.palette.white};

  &:focus {
    outline: none;
    box-shadow: 0 0 2em 0 ${({ theme }) => theme.palette.green};
  }
`;

const FormControl = styled.div``;

const FormLabel = styled.label`
  display: block;
  font-size: 0.85em;
`;

const FormInput = styled.input`
  margin: initial;
  padding: initial;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  text-align: inherit;
  text-transform: inherit;
  background-color: transparent;
  color: inherit;

  display: block;
  width: 100%;
  margin-top: 0.5rem;
  padding: 0.4em 0.8em;
  border: 2px solid ${({ theme }) => theme.palette.darkerLightBlue};
  border-radius: 0.5rem;
  box-shadow: none;
  resize: vertical;

  &::placeholder {
  }

  &:focus {
    box-shadow: 0 0 0.125rem 0.125rem
      ${({ theme }) => transparentize(0.25, theme.palette.lightBlue)};
    outline: none;
  }
`;

const FormFooter = styled.div`
  flex-basis: 100%;
  flex-grow: 1;
`;

const SubmitButton = styled(Button)`
  ${buttonIsLoading};

  width: 100%;
  max-width: 20ch;
`;

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      submitted: false,
      error: false,
    };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    this.setState({ loading: true });
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.state }),
    })
      .then(() => this.setState({ submitted: true, loading: false }))
      .catch(() => this.setState({ error: true, loading: false }));

    e.preventDefault();
  };

  render() {
    return (
      <div className={this.props.className}>
        {!this.state.submitted ? (
          <StyledContactForm
            name="contact"
            method="post"
            action="/thanks/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}
            isLoading={this.state.loading}
          >
            <p>
              Or use this form to leave us a message and we&apos;ll get back to
              you as soon as we can
            </p>
            <label htmlFor="bot-field" hidden>
              Don’t fill this out if you’re human:{" "}
              <input name="bot-field" id="bot-field" />
            </label>
            <FormControl>
              <FormLabel htmlFor="form-name">Your name</FormLabel>
              <FormInput
                name="name"
                id="form-name"
                type="text"
                placeholder="Firstname Surname"
                required
                onChange={this.handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="form-email">Your email address</FormLabel>
              <FormInput
                type="email"
                name="email"
                id="form-email"
                placeholder="name@example.com"
                required
                onChange={this.handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="form-message">Your message</FormLabel>
              <FormInput
                as="textarea"
                css="min-height: 4em;"
                name="message"
                id="form-message"
                placeholder="Please include as much information as possible"
                rows="6"
                required
                onChange={this.handleChange}
              />
            </FormControl>
            <FormFooter>
              <SubmitButton type="submit" isLoading={this.state.loading}>
                Submit
              </SubmitButton>
            </FormFooter>
          </StyledContactForm>
        ) : (
          <Success>Thanks for your enquiry, we&apos;ll be in touch!</Success>
        )}
      </div>
    );
  }
}
